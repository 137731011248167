import React, { Component } from 'react';
import CountUp from 'react-countup';
import AOS from 'aos';

import { Layout, SEO, Image, CaseStudies, TestimonialSlider, HeroSlider } from '../../components';
import { validateArray, validateSingleImage, validateRTE } from '../../components/utilities/functions';
import './index.scss';

class CaseStudyDetailPage extends Component {

	state = {
		updateCount: false
	}

	componentDidMount() {
		AOS.init({
			duration: 1000,
			disable: 'mobile',
			once: true
		});

		if (typeof window !== "undefined") {
			window.addEventListener("scroll", this.handleScroll);
		}
	}

	handleScroll = () => {
		const currentScrollPos = typeof window !== "undefined" && window.pageYOffset;
		const stickyContainer = document.getElementById("sticky-container");
		if (
			stickyContainer
			&& stickyContainer.offsetTop &&
			currentScrollPos >= (stickyContainer.offsetTop - window.innerHeight) && !this.state.updateCount
		) {
			this.setState({
				updateCount: true
			});
		}
	};

	render() {

		const { pageContext } = this.props;
		const {
			uid='', locale="",
			hero, url, seo,
			has_hero_slider, hero_slider, top_content, solution, cards_with_banner,
			highlights_top_content,
			highlights_section_bottom_content,
			highlights, case_studies, testimonials, our_approach, our_approach_blocks
		} = (pageContext.node) || {};

		const topHeading = (
			top_content &&
			top_content.heading &&
			<h2 className={(top_content.has_line_over_title ? 'title-lineover left' : '')}>{top_content.heading}</h2>
		);

		const windowWidth = (typeof window !== "undefined" && window.innerWidth && window.innerWidth > 1440) ? window.innerWidth : 1300;

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (

			<Layout className={`casestudy-wrapper animation-wrapper ${(!has_hero_slider && 'hasheaderHero')}`} pageURL={url}>
				<SEO pageURL={url} {...seo} extension={extension}/>
				{
					(
						has_hero_slider &&
						validateArray(hero_slider)
					) ?
						<HeroSlider data={hero_slider[0]} />
						:
						(hero && validateSingleImage(hero.hero_image)) &&
						<div
							className={'casestudy-hero-section' + (hero.align_center ? ' text-center' : '')}
							style={(hero.hero_image && hero.hero_image.url) &&
							{
								backgroundImage: 'url(' +
									(hero.hero_image.url + "?format=pjpg&auto=webp&width=" + windowWidth)
									+ ')'
							}}
						>
							<div className={'container ' + (hero.align_center ? ' container--980' : ' container--1300')}>
								{
									hero.small_title &&
									<h3 className={`subtitle`}>{hero.small_title}</h3>
								}
								{
									hero.heading &&
									<h1 className='hero-title'>{hero.heading}</h1>
								}
								{
									hero.description &&
									<div
										dangerouslySetInnerHTML={{ __html: hero.description }}
										className={`hero-description p__body1 ${(hero.is_full_width_description ? 'has_maxWidth' : '')}`}
									/>

								}
							</div>
						</div>
				}
				{
					top_content &&
					<div className='top-content box-10080'>
						<div className='container container--980'>
							<div className='row'>
								{
									topHeading &&
									<div className='col-12 col-768-6' data-aos="fade-right">
										{
											topHeading
										}
									</div>
								}
								{
									top_content.description &&
									<div data-aos="fade-left" className='col-12 col-768-6 author-quote p__body1' dangerouslySetInnerHTML={{ __html: top_content.description }} />
								}
							</div>

						</div>
					</div>
				}
				{
					(validateArray(our_approach_blocks)) &&
					<div className='bg-darkgrey box-100 theme--white'>
						<div className='container'>
							{
								our_approach.heading &&
								<h2 className='title-lineover'>{our_approach.heading}</h2>
							}
							{
								validateArray(our_approach_blocks) &&
								<div className='row our-approach-blocks'>
									{
										our_approach_blocks.map((item, index) =>
											<React.Fragment key={index}>
												{
													item.content &&
													(item.content.heading || item.content.description) &&
													<div className='block-col col-12 col-768-6 block-content' data-aos={`${((index % 2 === 0 && 'fade-right') || 'fade-left')}`} >
														{
															item.content.heading &&
															<h3 className='sec-title'>{item.content.heading}</h3>
														}
														{
															item.content.description &&
															<div className='p__body_16' dangerouslySetInnerHTML={{ __html: item.content.description }} />
														}
													</div>
												}
												{
													item.small_image_blocks &&
													validateArray(item.small_image_blocks.small_images) &&
													<div className='block-col col-12 col-768-6' data-aos={`${((index % 2 === 0 && 'fade-right') || 'fade-left')}`}>
														<div className='img-grid row' >
															{
																item.small_image_blocks.small_images.map((img, idx) =>
																	<div className='col-6 grid-img-col' key={idx}>
																		<Image
																			className="img-100"
																			data={img}
																			resizeWidth={300}
																			format={false}
																		/>
																	</div>
																)
															}
														</div>
													</div>
												}
												{
													item.large_image &&
													validateSingleImage(item.large_image.image) &&
													<div className='block-col col-12 col-768-6' data-aos={`${((index % 2 === 0 && 'fade-right') || 'fade-left')}`}>
														<Image
															data={{ image: item.large_image.image, image_alt: item.large_image.image_alt }}
															resizeWidth={700}
															format={false}
														/>
													</div>
												}
											</React.Fragment>
										)
									}
								</div>

							}
						</div>
					</div>
				}

				{
					solution &&
					<div className='solution-content box-10080 bg-grey has-top-border'>
						<div className='container container--980'>
							{
								(solution.heading || solution.description) &&
								<div className='row'>
									{
										solution.heading &&
										<div className='col-12 col-768-6'>
											<h2 className='title-lineover left'>{solution.heading}</h2>
										</div>
									}
									{
										validateRTE(solution.description) &&
										<div className='col-12 col-768-6 sol-description p__body_16' dangerouslySetInnerHTML={{ __html: solution.description }} />
									}
								</div>
							}

							{
								validateArray(solution.cards) &&
								solution.cards.map((item, index) =>
									<div className='sol-card-row row' key={index}>
										<div className={'content-col sol-card-col col-12 col-768-6 ' + (index % 2 === 0 ? 'order-2' : '')} data-aos={`${((index % 2 === 0 && 'fade-left') || 'fade-right')}`} key={index}>
											{
												item.heading &&
												<h4 className='cards-heading'>{item.heading}</h4>
											}
											{
												validateRTE(item.description) &&
												<div className='p__body_15' dangerouslySetInnerHTML={{ __html: item.description }} />
											}
										</div>
										{
											item.card_image &&
											<div className={'sol-card-col col-12 col-768-6 '} data-aos={`${((index % 2 === 0 && 'fade-right') || 'fade-left')}`}>
												<Image
													data={item.card_image}
													resizeWidth={500}
													format={false}
												/>
											</div>
										}
									</div>
								)
							}
						</div>
					</div>
				}

				<div className='text-center has-top-border hightlights__Section'>
					<div className='container container--980 box-80'>

						{
							highlights_top_content &&
							(highlights_top_content.heading || highlights_top_content.description) &&
							<>
								{
									highlights_top_content.heading &&
									<h2 className='title-lineover'>{highlights_top_content.heading}</h2>
								}
								{
									validateRTE(highlights_top_content.description) &&
									<div className='result__Content' dangerouslySetInnerHTML={{ __html: highlights_top_content.description }} />
								}
							</>
						}

						{
							validateArray(highlights) &&
							<>
								<div id="sticky-container" />
								<div className='highlights-wrapper'>
									{
										highlights.map((item, index) =>
											(item.count || validateRTE(item.description)) &&
											<div className='highl-col' key={index}>
												{
													item.count &&
													<h4 className='highl-count'>
														<CountUp
															delay={(item.count_value && item.count_value < 100 ? 1 : 0)}
															duration={(item.count_value && item.count_value <= 1000 ? 2.8 : 2)}
															start={(item.count_value && item.count_value <= 30 ? 100 : 0)}
															end={(this.state.updateCount ? item.count_value : 1000)}
															suffix={item.count_suffix && item.count_suffix}
															separator=','
														/>
													</h4>
												}
												{
													validateRTE(item.description) &&
													<div
														className='highl-desc'
														dangerouslySetInnerHTML={{ __html: item.description }}
													/>
												}
											</div>
										)
									}
								</div>
							</>
						}
						{
							validateRTE(highlights_section_bottom_content) &&
							<div dangerouslySetInnerHTML={{ __html: highlights_section_bottom_content }} />
						}
					</div>
				</div>

				{
					cards_with_banner &&
					validateArray(cards_with_banner.cards) &&
					<div className='solution-content box-10080 bg-grey has-top-border'>
						<div className='container container--980'>
							{
								cards_with_banner.cards.map((item, index) =>
									<div className='sol-card-row row' key={index}>
										<div className={'content-col sol-card-col col-12 col-768-6 ' + (index % 2 === 0 ? 'order-2' : '')} key={index}>
											{
												item.heading &&
												<h4 className='cards-heading'>{item.heading}</h4>
											}
											{
												item.description &&
												<div className='p__body_15' dangerouslySetInnerHTML={{ __html: item.description }} />
											}
										</div>
										{
											item.card_image &&
											<div className={'sol-card-col col-12 col-768-6 '}>
												<Image
													data={item.card_image}
													format={false}
												/>
											</div>
										}
									</div>
								)
							}
						</div>
					</div>

				}

				{
					validateArray(testimonials) &&
					<TestimonialSlider data={testimonials} />
				}

				{
					case_studies &&
					validateArray(case_studies.case_studies_list) &&
					<CaseStudies
						data={case_studies.case_studies_list}
						heading={case_studies.heading || ''}
						headingClass='title-linethrough'
						className='box-10080  has-top-border'
					/>
				}
			</Layout >
		)
	}
}

export default CaseStudyDetailPage;
